
import {
    article1,
    article2,
    article3,
    article4,
    article5,
    article6,
    commentimage,
    pen,
    aboutImage, book1, book2, person1, person2, person3, person4, person5, profile, verify
  } from "../../public/images";

export const articles = [
    {
      id: "1",
      coverImage: article1,
      image: article1,
      author: "ناصر الأحمد",
      profession: " في مدونة صحافة",
      title: "عامل النظافة البورتوريكي الذي أصبح الرئيس التنفيذي لطيران دولي",
      subTitle: "عامل النظافة البورتوريكي الذي أصبح الرئيس التنفيذي لطيران دولي",
      text: "كيف لصبي بورتوريكي بدأ بتنظيف دورات مياه الطائرات في جزيرة صغيرة في المحيط أن يصبح مسؤولًا عن خدمة ٤٠٠ ألف مسافر و٤ آلاف طائرة تجوب العالم كل يوم؟",
      date: "29 يوليو 2024",
      type: "مسودة",
    },
    {
      id: "2",
      coverImage: article2,
      image: article2,
      author: "وليد عبدالله",
      profession: "في مدونة فكرة",
      title: "سر تحويل أحلامك إلى مهام يوميّة",
      subTitle: "عامل النظافة البورتوريكي الذي أصبح الرئيس التنفيذي لطيران دولي",
  
      text: "كيف لصبي بورتوريكي بدأ بتنظيف دورات مياه الطائرات في جزيرة صغيرة في المحيط أن يصبح مسؤولًا عن خدمة ٤٠٠ ألف مسافر و٤ آلاف طائرة تجوب العالم كل يوم؟",
      date: "29 يوليو 2024",
      type: "نشرت",
    },
    {
      id: "3",
      coverImage: article3,
      image: article3,
      author: "وليد عبدالله",
      profession: "في مدونة فكرة",
      title: "هل حلمت أن تصبح طيّارًا حين تكبر؟",
      subTitle: "عامل النظافة البورتوريكي الذي أصبح الرئيس التنفيذي لطيران دولي",
  
      text: "كيف لصبي بورتوريكي بدأ بتنظيف دورات مياه الطائرات في جزيرة صغيرة في المحيط أن يصبح مسؤولًا عن خدمة ٤٠٠ ألف مسافر و٤ آلاف طائرة تجوب العالم كل يوم؟",
      date: "29 يوليو 2024",
      type: "نشرت",
    },
    {
      id: "4",
      coverImage: article4,
      image: article4,
      author: "ناصر الأحمد",
      profession: "في مدونة فكرة",
      title: "أحد عشر درسًا تعلمتها من أخطائي في 2023",
      subTitle: "عامل النظافة البورتوريكي الذي أصبح الرئيس التنفيذي لطيران دولي",
  
      text: "كيف لصبي بورتوريكي بدأ بتنظيف دورات مياه الطائرات في جزيرة صغيرة في المحيط أن يصبح مسؤولًا عن خدمة ٤٠٠ ألف مسافر و٤ آلاف طائرة تجوب العالم كل يوم؟",
      date: "29 يوليو 2024",
      type: "نشرت",
    },
    {
      id: "5",
      coverImage: article5,
      image: article5,
      author: "ناصر الأحمد",
      profession: "في مدونة فكرة",
      title: "هل أنت صانع للنجاح أم مبتكر للأنماط؟",
      subTitle: "عامل النظافة البورتوريكي الذي أصبح الرئيس التنفيذي لطيران دولي",
  
      text: "كيف لصبي بورتوريكي بدأ بتنظيف دورات مياه الطائرات في جزيرة صغيرة في المحيط أن يصبح مسؤولًا عن خدمة ٤٠٠ ألف مسافر و٤ آلاف طائرة تجوب العالم كل يوم؟",
      date: "29 يوليو 2024",
      type: "مسودة",
    },
    {
      id: "6",
      coverImage: article6,
      image: article6,
      author: "ناصر الأحمد",
      profession: "في مدونة فكرة",
      title: "وظائف مملّة أخفت وراءها مواهب غيّرت العالم",
      subTitle: "عامل النظافة البورتوريكي الذي أصبح الرئيس التنفيذي لطيران دولي",
  
      text: "كيف لصبي بورتوريكي بدأ بتنظيف دورات مياه الطائرات في جزيرة صغيرة في المحيط أن يصبح مسؤولًا عن خدمة ٤٠٠ ألف مسافر و٤ آلاف طائرة تجوب العالم كل يوم؟",
      date: "29 يوليو 2024",
      type: "نشرت",
    },
  ];

  export const latestSearches = [
    "الكتابة",
    "الفن",
    "الأزياء",
    "علم البيانات",
    "طب",
    "فن",
    "تاريخ",
    "الصحافة",
    "التقنية",
    'تكنولوجيا',
    "علوم",
    'الترميز',
    'تحسين الذات'
  
    
  ];

  
  export const books = [
    {name:'صيّاد الظل',
      image:book1,
      desc:'في مدن العزلةأختار هواءً مرئيّاً كي يصحبَنيأبتكر له ظلاًوأدرّب قدميه على موسيقى الخطواتْ',
      date:'2012 محمد الضبع',
      verify:true,
    },
    {name:'مكتبة منتصف الليل',
      image:book2,
      desc:"بين الحياة والموت هنالك مكتبة، وفي تلك المكتبة، تمتد الرفوف بلا نهاية. كلّ كتاب يقدّم فرصة لتجربة حياة أخرى. لاستكشاف ما يمكن أن يحدث بعد اتخاذك لقرارات أخرى ... إن سنحت لك الفرصة للعودة للوراء ومحو ندمك، هل ستغيّر شيئًا؟",
      date:'2020 مات هيغ ',
      translatedBy:' ترجمة: محمد الضبع',
      verify:true,
    },
    {name:'صيّاد الظل',
      image:book1,
      desc:'في مدن العزلةأختار هواءً مرئيّاً كي يصحبَنيأبتكر له ظلاًوأدرّب قدميه على موسيقى الخطواتْ',
      date:'2012 محمد الضبع',
      verify:true,
    },
    {name:'مكتبة منتصف الليل',
      image:book2,
      desc:"بين الحياة والموت هنالك مكتبة، وفي تلك المكتبة، تمتد الرفوف بلا نهاية. كلّ كتاب يقدّم فرصة لتجربة حياة أخرى. لاستكشاف ما يمكن أن يحدث بعد اتخاذك لقرارات أخرى ... إن سنحت لك الفرصة للعودة للوراء ومحو ندمك، هل ستغيّر شيئًا؟",
      date:'2020 مات هيغ ',
      translatedBy:' ترجمة: محمد الضبع',
      verify:true,
    },
  ]
  