'use client'
import { ArrowUp, ChevronLeft } from 'lucide-react';
import { useState } from 'react';
import { IoClose } from 'react-icons/io5';
import Modal from 'react-modal';
import { person1, person2, profile } from '../../../../../public/images';
import Image from 'next/image';
import { Input } from '@/components/ui/input';

const CommentModal = ({ isOpen, onClose }) => {
    const customStyles = {
        content: {
            top: '50%',
            left: '50%',
            right: 'auto',
            bottom: 'auto',
            marginRight: '-50%',
            width: "90%",
            maxWidth: '400px',
            height: "500px",  // Ensure height is set for modal
            transform: 'translate(-50%, -50%)',
            background: "white",
            boxShadow: "0 0 10px rgba(0, 0, 0, 0.3)",
            padding: 0,  // Remove default padding
        },
        // overlay: {
        //     backgroundColor: "rgba(0, 0, 0, 0.1)",  // Added opacity to overlay
        // },
    };

    const comments = [
        {
            image: person1,
            person: 'user',
            name: 'محمد أمين',
            date: 'منذ 4 دقائق',
            comment: 'نص مؤثر وجميل'
        },
        {
            image: profile,
            person: 'author',
            name: 'محمد الضبع',
            date: 'منذ 4 دقائق',
            comment: 'شكرًا لمشاركتك'
        },
        {
            image: person1,
            person: 'user',
            name: 'محمد أمين',
            date: 'منذ 4 دقائق',
            comment: 'نص مؤثر وجميل'
        },
        {
            image: profile,
            person: 'author',
            name: 'محمد الضبع',
            date: 'منذ 4 دقائق',
            comment: 'شكرًا لمشاركتك'
        },
        {
            image: person1,
            person: 'user',
            name: 'محمد أمين',
            date: 'منذ 4 دقائق',
            comment: 'نص مؤثر وجميل'
        },
        {
            image: profile,
            person: 'author',
            name: 'محمد الضبع',
            date: 'منذ 4 دقائق',
            comment: 'شكرًا لمشاركتك'
        },
        {
            image: person1,
            person: 'user',
            name: 'محمد أمين',
            date: 'منذ 4 دقائق',
            comment: 'نص مؤثر وجميل'
        },
        {
            image: profile,
            person: 'author',
            name: 'محمد الضبع',
            date: 'منذ 4 دقائق',
            comment: 'شكرًا لمشاركتك'
        },
        // More comments...
    ];

    const [selected, setSelected] = useState('كل التعليقات');

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            style={customStyles}
            contentLabel="Comment Modal"
            closeTimeoutMS={500}
        >
            <div className='flex flex-col relative gap-3 h-full'>
                <div className="flex w-full justify-between items-center p-2 border-b">
                    <IoClose className="cursor-pointer" size={20} onClick={onClose} />
                    <div className='flex gap-2 items-center'>
                        <p onClick={() => setSelected('جديد التعليقات')} className={`text-[12px] ${selected === 'جديد التعليقات' ? "text-[#8641BD]" : "text-[#8C97A7]"} font-[500] cursor-pointer`}>جديد التعليقات</p>
                        <p onClick={() => setSelected('كل التعليقات')} className={`text-[12px] font-[500] ${selected === 'كل التعليقات' ? "text-[#8641BD]" : "text-[#8C97A7]"} cursor-pointer`}>كل التعليقات</p>
                        <p className='text-[16px] items-center flex font-[600]'>
                            <ChevronLeft className="cursor-pointer" width={20} height={20} />
                            التعليقات
                        </p>
                    </div>
                </div>
                <div style={{scrollbarWidth:'none'}} className="flex-1 overflow-y-auto p-3">
                    {comments.map((comment, i) => (
                        <div key={i} className={`flex flex-col gap-2 ${comment.person === 'author' ? 'bg-[#F4F4F4]' : 'bg-white'} rounded-md p-3`}>
                            <span className='w-full items-center justify-between flex'>
                                <p className='text-[8px] font-[500]'>{comment.date}</p>
                                <span className='flex gap-2 items-center'>
                                    <p className='text-[12px] font-[600]'>{comment.name}</p>
                                    <Image alt='' src={comment.image} width={20} height={20} />
                                </span>
                            </span>
                            <span className='text-[12px] text-right  font-[400] text-opacity-60'>{comment.comment}</span>
                        </div>
                    ))}
                </div>
                <div className="flex justify-between items-center p-2 border-t bg-white">
                    <span className='bg-[#8641BD] self-center cursor-pointer p-1 rounded-full'>
                        <ArrowUp size={20} color='white' />
                    </span>
                    <input className='outline-none w-full text-right p-1' placeholder='اكتب تعليقك هنا' />
                </div>
            </div>
        </Modal>
    );
}

export default CommentModal;
